export const roundPrice = (num, decimalPlaces) => {
  if (isNull(num)) {
    return 0.0
  }
  return (
    Math.round(parseFloat(num) * Math.pow(10, decimalPlaces)) /
    Math.pow(10, decimalPlaces)
  )
}

const isNull = obj => {
  return typeof obj === 'undefined' || obj === undefined || obj === null
}
