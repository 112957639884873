<template>
  <div class="content">
    <div class="container-fluid">
      <Notifications />

      <div v-if="impersonateUser" class="col-xs-12">
        <div class="alert impersonation-alert  flex-alert">
          <div class="flex-alert-text">
            You are viewing the app as <strong>{{ impersonateUser.name }}</strong>
          </div>
          <button type="button" class="btn btn-primary" @click="exitImpersonation()">Exit</button>
        </div>
      </div>

      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DaterComponent from '@/components/shared/DaterComponent.vue'
import Notifications from '../../UIComponents/NotificationPlugin/Notifications.vue';
export default {
  components: {
    DaterComponent,
    Notifications
  },
  computed: {
    ...mapGetters({
      impersonateUser: 'authentication/impersonateUser',
    })
  },
  methods: {
    exitImpersonation() {
      this.$store.dispatch('authentication/stopImpersonation');
      this.$router.push({ name: 'Affiliates' });
    }
  }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .1s
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0
}


.impersonation-alert {
  margin-bottom: 20px;
  background-color: #212121;
  color: #FFF;
}

.flex-alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-alert-text {
  flex-grow: 1;
}
</style>
