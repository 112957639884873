<template>
  <div>
    
    <div class="row">
      <div class="col-xs-12 col-sm-3 col-lg-5 text-left"></div>
      <div class="col-xs-12 col-sm-8 col-lg-7 text-right negate-float">
        <DaterComponent />
      </div>
    </div>

    <div class="row">
      <div
        class="col-lg-4 col-sm-4"
        v-for="(grStat, index) in statsForTrips" :key="'grStat-'+ index">
        <StatsCard :class="{ busy: isWaitingForTopSellingData }" :loading="isWaitingForTopSellingData">
          <template v-slot:header>
            <div class="icon-big" :class="`icon-${grStat.type}`">
              <i :class="grStat.icon"></i>
            </div>
          </template>
          <template v-slot:content>
            <div class="numbers">
              <p>{{ grStat.title }}</p>
              {{ grStat.value }}
            </div>
          </template>
          <template v-slot:extra v-if="grStat.extra">
            <table class="table affiliateStats-table">
              <tbody>
                <tr v-for="(graph, index) in grStat.extra" :key="'graph'+ index">
                  <td class="name" v-html="graph.name"></td>
                  <td class="value">{{ graph.value }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </StatsCard>
      </div>
    </div>

    <!--Charts-->
  </div>
</template>
<script>
import Api from '@/services/ApiGateway'
import Ports from '@/repositories/ports';


import DaterComponent from '@/components/shared/DaterComponent.vue'
import StatsCard from '@/components/UIComponents/Cards/StatsCard.vue'
import { mapGetters } from 'vuex';


const getTopSellingPorts = (topSellingPorts) => {
  return topSellingPorts.map(port => ({
    name: Ports.locationName(port.ArrStation),
    value: port.tripsCount
  }));
}


const getTopSellingTrips = (topSellingTrips) => {
  return topSellingTrips.map(trip => ({
    name: Ports.locationName(trip.DepStation) + ' <i class="fa fa-angle-right"></i> ' + Ports.locationName(trip.ArrStation),
    value: trip.tripsCount
  }))
}

export default {
  components: {
    StatsCard,
    DaterComponent
  },
  data() {
    return {
      isWaitingForTopSellingData: true,
      topSellingPorts: [],
      topSellingTrips: []
    }
  },
  watch: {
    // Executes instantly once component comes into view, and each time the date changes
    getActiveDates(previous, current) {
      this.getTopSellingData();
    }
  },
  computed: {    
    ...mapGetters({
      getActiveDates: 'datesModule/getActiveDates',
      impersonateUser: 'authentication/impersonateUser',
    }),
    statsForTrips() {
      return [
        {
          type: 'warning',
          icon: 'fa fa-trophy',
          title: 'Top selling destinations',
          value: '',
          extra: this.topSellingPorts
        },
        {
          type: 'warning',
          icon: 'fa fa-star',
          title: 'Top selling trips',
          value: '',
          extra: this.topSellingTrips
        },
      ]
    },
  },
  methods: {
    getTopSellingData() {
      this.isWaitingForTopSellingData = false;
      Api.getTopSellingData(this.impersonateUser).then(result => {
        const { topSellingPorts, topSellingTrips } = result.data;
        this.topSellingPorts = getTopSellingPorts(topSellingPorts);
        this.topSellingTrips = getTopSellingTrips(topSellingTrips);
        this.isWaitingForTopSellingData = false;
      })
    }
  }
}
</script>
<style>
</style>
