let locationsMap = []

function PortsRepository() {}

PortsRepository.prototype.initialize = function({ ports }) {
  ports.forEach(location => {
    // insert location to list
    locationsMap[location.LocationAbbr] = {
      ...location,
      short: location.short || location.alias,
    }
  })
}

PortsRepository.prototype.location = function(abbr) {
  return locationsMap[abbr]
}

PortsRepository.prototype.locationName = function(abbr) {
  return (locationsMap[abbr] && locationsMap[abbr].alias) || abbr
}

export default new PortsRepository()
