<template>
  <div class="row">
    <div class="banner col-xs-6">
      <img
        class="ferry-logo"
        src="https://images.ferryhopper.com/assets/logo/ferryhopper-logo-white-medium.png"
        width="150px"
      />
      <h1 class="center-block">Affiliates</h1>
    </div>
    <div class="menu-container col-xs-6">
      <BurgerMenu :menu-links="menuLinks" />
    </div>
    <div class="col-xs-12"></div>
  </div>
</template>

<script setup>
import BurgerMenu from '@/components/shared/BurgerMenu.vue';

const menuLinks = [
  {
    linkName: 'Home',
    path: '/home',
    internalLink: true,
  },
  {
    linkName: 'About',
    path: '/about',
    internalLink: true,
  },
  {
    linkName: 'Contact us',
    path: 'https://www.ferryhopper.com/en/contact',
    internalLink: false,
  },
];
</script>
