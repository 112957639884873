const lockUi = {
    namespaced: true,
    state: {
        blockUi: false,
        showSidebar: false,
        sidebarLinks: [
            {
                name: 'Overview',
                icon: 'fa fa-bar-chart',
                path: '/admin/overview',
                admin: false
            },
            {
                name: 'Bookings',
                icon: 'fa fa-table',
                path: '/admin/bookings-list',
                admin: false
            },
            {
                name: 'Trips',
                icon: 'fa fa-list',
                path: '/admin/trips-list',
                admin: false
            },
            {
                name: 'Statistics',
                icon: 'fa fa-line-chart',
                path: '/admin/statistics',
                admin: false
            },
            {
                name: 'Widget',
                icon: 'fa fa-window-restore',
                path: '/admin/widget',
                admin: false
            },
            {
                name: 'New User',
                icon: 'fa fa-user',
                path: '/admin/user_registration',
                admin: true
            },
            {
                name: 'Affiliates',
                icon: 'fa fa-users',
                path: '/admin/affiliates-list',
                admin: true
            }

        ]
    },
    mutations: {
        lock(state) {
            state.blockUi = true;
        },
        unlock(state) {
            state.blockUi = false;
        },
        toggleSidebar(state) {
            state.showSidebar = !state.showSidebar;
        },
        hideSidebar(state) {
            state.showSidebar = false;
        }
    },
    actions: {
        lockUiAction({ commit }) {
            commit('lock')
        },
        unlockUiAction({ commit }) {
            commit('unlock')
        },
        toggleSidebar({ commit }) {
            commit('toggleSidebar')
        },
        hideSidebar({ commit }) {
            commit('hideSidebar')
        }
    }
}

export default lockUi