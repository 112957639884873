/**
 * Creates an affiliate code from a given set of characters
 */
function generateAffiliateCode() {
  let standardCodeLength = 6;
  var text = "";
  var possible = "abcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < standardCodeLength; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

/**
 * Creates a random hex color
 */
function hex() {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
}


export {
  generateAffiliateCode,
  hex,
}
