


export const keyToLabel = (key) => {
    let position = 0;
    for (var i = 0; i < key.length; i++) {
        if (key[i].match(/[A-Z]/) != null) {
            position = i;
        }
    }

   return finalLabel(position,key)
}


const finalLabel = (position,label) => {
    let nameCapitalized = '';
    if (position != 0) {
        var space = " ";
        var output = [label.slice(0, position), space, label.slice(position)].join('');
        nameCapitalized = output.charAt(0).toUpperCase() + output.slice(1)
    } else {
        nameCapitalized = label.charAt(0).toUpperCase() + label.slice(1);  //for only one word
    }

    return nameCapitalized;
}

