<template>
  <div>
    <h2>Welcome</h2>
    <p>
      With our affiliate program you can monetize your website or blog content
      by recommending the best ferry booking platform out there.
    </p>
    <br />
    <div>
      <h4>Want to join?</h4>
      <p>
        Please send us an
        <a href="mailto:affiliates.signup@ferryhopper.com">email</a> and a
        member of our team will contact you to proceed with your registration.
        We have worked out the complicated parts for you, so the integration
        process is super easy.
      </p>
      <p>
        To learn more about our affiliate program, just click
        <router-link :to="{ path: '/about' }">here</router-link>
      </p>
    </div>
  </div>
</template>

<script setup>

</script>
