<template>
  <div class="card">
    <div class="content">
      <div class="stats-card__header">
        <slot name="header"></slot>
        <slot name="content"></slot>
      </div>
      <div class="content" v-if="loading">
        <LoadingListOverlay></LoadingListOverlay>
      </div>            
      <slot name="extra" v-else></slot>
    </div>
      <div class="footer">
        <hr v-if="hasFooterSlot"/>
        <slot name="footer"></slot>
      </div>
  </div>
</template>
<script>

import LoadingListOverlay from '@/components/shared/LoadingListOverlayComponent'
export default {
  name: 'StatsCard',
  props: ['loading'],
  components: {
    LoadingListOverlay
  },
  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer;
    },
  }
}
</script>
<style scoped lang="scss">
.stats-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
