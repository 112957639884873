<template>
  <div class="row">
    <!-- action buttons -->
    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-5 text-left">
      <button
        class="btn btn-primary btn-lg btn-link mb5"
        v-on:click="createReport()"
        :disabled="isComponentBusy">
        <span v-if="!isCreatingReport">
          <i class="fa fa-save"></i> Generate report
        </span>
        <span v-if="isCreatingReport">
          <i class="fa fa-spinner fa-spin"></i> Creating...
        </span>
      </button>
    </div>

    <!-- date changers -->
    <div class="col-xs-12 col-sm-8 col-md-8 col-lg-7 text-right">
      <DaterComponent />
    </div>

    <!-- data table -->
    <div class="col-xs-12 col-md-12">
      <div class="card spinner-container" :class="{ busy: isComponentBusy }" style="padding-bottom:5px;">

        <PaginatedTableComponent
          :maxPage="maxPage"
          :title="'Bookings'"
          :sub-title="shortdate(dater.activeDate)"
          :data="bookings.data"
          :columns="bookings.columns"
          :headings="bookings.headings"
          :currentPage="currentPage"
          :lockList="waitForListFill"
          @changePage="changePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { shortdate } from '../filters'
import Dater from '@/repositories/dater'
import DaterComponent from '@/components/shared/DaterComponent.vue'
import PaginatedTableComponent from '@/components/lists/PaginatedTableComponent.vue'
import Api from '@/services/ApiGateway'
import { mapGetters, mapState } from 'vuex'

const filterBookings = (bookings) => {
  return bookings.map(booking => ({
    ...booking,
    bookingPrice: Number(booking.bookingPrice),
    bookedDate:  dayjs(booking.createdAt).format('DD/MM/YYYY'),
  }));
};

export default {
  components: {
    PaginatedTableComponent,
    DaterComponent,
  },
  data() {
    return {
      dater: Dater,
      isCreatingReport: false,
      bookingsList: [],
      paginationSize: window.ferryhopper && window.ferryhopper.pagination_size || 50,
      tableStructure: []
    }
  },
  watch: {
    // Executes instantly once component comes into view, and each time the date changes
    getActiveDates(previous, current) {
      this.$store.dispatch('bookingPagination/resetBookingsPageAction')
      this.getBookingsList(1);
    }
  },
  computed: {
    ...mapState({
      currentPage: state => state.bookingPagination.currentBookingListsPage,
      waitForListFill: state => state.bookingPagination.bookingLockList,
      transactionsCount: state => state.statisticsModule.transactionsCount
    }),
    ...mapGetters({
      getActiveDates: 'datesModule/getActiveDates',
      impersonateUser: 'authentication/impersonateUser',
    }),
    bookings() {
      const headings = this.tableStructure.map(c => c.columnName);
      const columns = this.tableStructure.map(c => c.itemProperty);
      return {
        headings: [...headings],
        columns: [...columns],
        data: [...this.bookingsList],
      }
    },
    isComponentBusy() {
      return this.isCreatingReport || this.waitForListFill
    },
    maxPage() {
      return Math.floor(this.transactionsCount / this.paginationSize)
    }
  },
  methods: {
    shortdate,
    createReport() {
      this.isCreatingReport = true
      Api.userReport('bookings').then(res => {
        window.open(res.data.url, 'name')
      }).finally(() => this.isCreatingReport = false);
    },
    changePage(page) {
      this.$store.dispatch('bookingPagination/changeBookingsPageAction', page)
      const nextPage = page + 1;
      this.getBookingsList(nextPage);
    },
    getBookingsList(page) {
      this.$store.dispatch('bookingPagination/lockBookingList')
      Api.fetchBookingsTablePage(page, this.impersonateUser).then(result => {
        const { model, bookings, bookingsCount, tableStructure } = result.data;
        this.tableStructure = tableStructure;
        this.$store.dispatch('statisticsModule/setModel', model);
        if (!!bookingsCount) {
          this.$store.dispatch('statisticsModule/setTransactionsCount', bookingsCount)
        }
        this.bookingsList = filterBookings(bookings);
        this.$store.dispatch('bookingPagination/unlockBookingList')
      }).catch(error => console.log(error))
    }
  },
}
</script>
