<template>
  <div>
    <div class="header">
      <slot name="header">
        <h4 class="title">{{ title }}</h4>
      </slot>

      <PaginatedTablePaginationButtons :currentPage="currentPage" :maxPage="maxPage" :pageRange="pageRange"
                                       @onPageClick="fetchCurrentPage" v-if="maxPage > 0"/>

    </div>
    <div>
      <div class="content table-responsive table-full-width">
        <LoadingListOverlay v-if="lockList"></LoadingListOverlay>
        <table class="table" :class="tableClass" v-else>
          <thead>
          <th v-for="heading in headings" :key="'heading-' + heading">{{ heading }}</th>
          </thead>
          <tbody>
          <tr v-for="(item, itemIndex) in filteredData" :key="'filtered-item-' + itemIndex"
              :class="{ 'isCancelled': item.isCancelled === '1' }">
            <td v-for="(column, columnIndex) in columns" :key="'item-column-' + columnIndex">
              <span v-if="hasValue(item, column)" v-html="itemValue(item, column)"></span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <PaginatedTablePaginationButtons :currentPage="currentPage" :maxPage="maxPage" :pageRange="pageRange"
                                       @onPageClick="fetchCurrentPage" v-if="maxPage > 0"/>

    </div>
  </div>
</template>

<script>
import LoadingListOverlay from '@/components/shared/LoadingListOverlayComponent'
import PaginatedTablePaginationButtons from './PaginatedTablePaginationButtons'

const euroFormat = Intl.NumberFormat('en-DE', {
  style: 'currency',
  currency: 'EUR'
});

export default {
  components: {
    LoadingListOverlay,
    PaginatedTablePaginationButtons
  },
  props: {
    headings: Array,
    columns: Array,
    data: Array,
    type: {
      type: String, // striped | hover
      default: 'Striped',
    },
    title: {
      type: String,
      default: '',
    },
    maxPage: {
      default: 0,
    },
    currentPage: {
      default: 0,
    },
    lockList: {
      default: true,
    },
  },
  data: () => ({
    pagination_range: 4,
  }),
  computed: {
    tableClass() {
      return `table-${this.type}`
    },
    filteredData() {
      return this.data
    },
    pageRange() {
      let range = []
      if (this.maxPage < this.pagination_range * 2 + 1) {
        range = Array.apply(null, {length: this.maxPage + 1}).map(
          Number.call,
          Number
        )
      } else {
        for (
          let i = this.currentPage - this.pagination_range;
          i <= this.currentPage + this.pagination_range;
          i++
        ) {
          if (i >= 0 && i <= this.maxPage) {
            range.push(i)
          }
        }
      }

      return range
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column] !== 'undefined'
    },
    itemValue(item, column) {
      if (column === 'locale') {
        return `<img src="https://images.ferryhopper.com/assets/flags/${item[column]}.svg" alt="flag"/>`
      }

      if ((column === 'bookingDate') || column === 'departureDate' || column === 'createdAt' || column === 'bookedDate') {
        return item[column];
      }

      if (column === 'itemGross' || column === 'itemRevenues') {
        return euroFormat.format(item[column])
      }

      if (column === 'initialBookingAmount' && item[column] !== '-') {
        return euroFormat.format(item[column])
      }

      return `<span>${item[column]}</span>`
    },
    fetchCurrentPage(page) {
      this.$store
        .dispatch('authentication/validateActiveSession')
        .then(() => this.$emit('changePage', page))
        .catch((e) => {
          this.$router.push('home')
          this.$store.dispatch('lockUi/unlockUiAction')
        })
    },
  },
}
</script>

<style></style>
