<template>
  <div>

    <button class="btn btn-default btn-sm date-button" @click="toggleCalendar">
      <i :class="{ 'fa fa-calendar': !isCalendarVisible, 'fa fa-close': isCalendarVisible }"></i> {{ dater.activeMonthStr }}
    </button>

    <div class="datepicker" v-show="isCalendarVisible">
      <Datepicker
        @input="changedDate"
        v-model="dateValue"
        ref="programaticOpen"
        :inline="true"
        :minimum-view="'month'"
        :maximum-view="'month'"
        :initial-view="'month'"
        :disabled-dates="disabledDates"
      />
    </div>
  </div>
</template>

<script>
import Dater from '@/repositories/dater'  
import Datepicker from 'vuejs3-datepicker';


export default {  
  components: {
    Datepicker
  },
  data() {
    return {
      dater: Dater,
      dateValue: '',
      isCalendarVisible: false,
      disabledDates: {},
    }
  },
  created() {
    this.$store.dispatch('datesModule/setActiveDates', this.dater.getFormattedArray());
  },
  mounted() {
    this.disableDates();
  },
  methods: {
    toggleCalendar() {
      this.$refs.programaticOpen.showCalendar()
      this.isCalendarVisible = !this.isCalendarVisible;
    },
    changedDate(dateValue) {
      this.$store
      .dispatch('authentication/validateActiveSession')
      .then(() => {
        this.isCalendarVisible = false
        this.dater.getDate(dateValue)        
        this.$store.dispatch('datesModule/setActiveDates', this.dater.getFormattedArray());
      })
      .catch(() => {
        this.$router.push({ name: 'home'})
        this.$store.dispatch('lockUi/unlockUiAction')
      })
     
    },
    disableDates() {
      this.disabledDates = this.dater.disabledDatesInDatepicker()
    }
  },
}
</script>
<style>
.datepicker {
  position: absolute;
  right: 20px;
  z-index: 100;
}

.vuejs3-datepicker #calendar-div {
  width: 300px;
}

</style>
