import routes from '@/routes/routes'
import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store/index'

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: 'active'
})

  
// redirect to login page if not logged in and trying to access a restricted page
router.beforeEach((to, from, next) => {

  // we do not need a guard for public paths
  if ((to.meta && to.meta.public === true)) {
    next();
    return;
  }

  store.dispatch('authentication/validateActiveSession').then(
    () => next()
  ).catch(() => {      
    store.dispatch('lockUi/unlockUiAction')
    next({name: 'home'});
  })
});

export default router;