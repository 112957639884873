<template>
    <div ref="target">
        <div class="btn-group" :class="{ 'open' :showMenu }">
          <button id="burger-button" @click="menuButtonPressed" type="button"
                  class="btn btn-small btn-default dropdown-toggle"
                  data-toggle="dropdown">
              <div class="burger-icon-stripes"></div>
              <div class="burger-icon-stripes"></div>
              <div class="burger-icon-stripes"></div>
              <div class="burger-icon-stripes"></div>
          </button>

          <ul id="left-aligned-menu" class="dropdown-menu" role="menu" >
              <li v-for="(link, linkIndex) in menuLinks" :key="'menu-link-' + linkIndex">
                  <router-link v-if="link.internalLink" :to="link.path" class="inside-links">{{ link.linkName }}</router-link>
                  <a v-else :href="link.path" class="inside-links">{{ link.linkName }}</a>
              </li>
          </ul>
      </div>
    </div>
  </template>
  
<script setup>
import { ref, defineProps } from 'vue'
import { onClickOutside } from '@vueuse/core'


defineProps({
    menuLinks: Array
})

const target = ref(null)
let showMenu = ref(false);

onClickOutside(target, (event) => showMenu.value = false)
const menuButtonPressed = () => showMenu.value = !showMenu.value;

</script>