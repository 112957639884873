<template>
  <div data-notify="container" class="col-xs-11 col-sm-4 alert open top right" role="alert" :class="[alertType]" :style="customPosition" data-notify-position="top-center">
    <button type="button"  class="close" data-notify="dismiss" style="position: absolute; right: 10px; top: 5px; z-index: 1033; height:32px; font-size:30px;" @click="close">×
    </button>
    <div class="row">
      <div class="col-xs-12">
        <slot name="message">
          <div class="text-left" v-html="message"></div>
        </slot>
      </div>
    </div>  
  </div>
</template>
<script>
import { mapState } from 'vuex'
  export default {
    name: 'notification',
    props: {
      message: String,
      verticalAlign: {
        type: String,
        default: 'top'
      },
      horizontalAlign: {
        type: String,
        default: 'right'
      },
      type: {
        type: String,
        default: 'info'
      },
      timeout: {
        type: Number,
        default: 15000
      }
    },
    data () {
      return {}
    },
    computed: {
      ...mapState({
        notifications: state => state.notificationsModule.notifications
      }),
      alertType () {
        return `alert-${this.type}`
      },
      customPosition () {
        let initialMargin = 120
        let alertHeight = 90
        let sameAlertsCount = this.notifications.filter((alert) => {
          return alert.horizontalAlign === this.horizontalAlign && alert.verticalAlign === this.verticalAlign
        }).length
        let pixels = (sameAlertsCount - 1) * alertHeight + initialMargin
        let styles = {}
        if (this.verticalAlign === 'top') {
          styles.top = `${pixels}px`
        } else {
          styles.bottom = `${pixels}px`
        }
        return styles
      }
    },
    methods: {
      close () {
        this.$emit('on-close')
      }
    },
    mounted () {
      if (this.timeout) {
        setTimeout(this.close, this.timeout)
      }
    }
  }

</script>
<style lang="scss" scoped>
  @import "../../../assets/sass/paper/variables";
  
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .3s
  }
  
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0
  }
  
  .alert {
    border: 0;
    border-radius: 0;
    color: #FFFFFF;
    padding: 10px 15px;
    font-size: 14px;
    z-index: 100;
    display: inline-block;
    position: fixed;
    transition: all 0.5s ease-in-out;
  
    &.center {
      left: 0px;
      right: 0px;
      margin: 0 auto;
    }
    &.left {
      left: 20px;
    }
    &.right {
      right: 20px;
    }
    .container & {
      border-radius: 4px;
    }
    .navbar & {
      border-radius: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 85px;
      width: 100%;
      z-index: 3;
    }
    .navbar:not(.navbar-transparent) & {
      top: 70px;
    }
  
    .close~span {
      display: block;
      max-width: 89%;
    }
  
    &[data-notify="container"] {
      width: 350px;
      padding: 10px 10px 10px 20px;
      border-radius: $border-radius-base;
    }
  }
  
  .alert-info {
    background-color: $bg-info;
    color: $info-states-color;
  }
  
  .alert-success {
    background-color: $bg-success;
    color: $success-states-color;
  }
  
  .alert-warning {
    background-color: $bg-warning;
    color: $warning-states-color;
  }
  
  .alert-danger {
    background-color: $bg-danger;
    color: $danger-states-color;
  }
</style>
