<template>
<div class="form-group">
  <label v-if="label" class="input-labels">
    {{ label }}
  </label>
  <input class="form-control border-input" v-bind="$props" :value="value" @input="onChange">
</div>
</template>

<script>
export default {
  name: 'SimpleInput',
  props: {
    bindAttribute: {
      bindAttribute: String,
      default: 'text'
    },
    label: String,
    name: String,
    readonly: Boolean,
    placeholder: String,
    value: null,
    type: String,
  },
  data() {
    return {}
  },
  methods: {
    onChange: function($event) {
      this.$emit('onInputChange', $event.target.value, this.bindAttribute)
    }
  }
}
</script>
