<template>
<div>
  <!-- iframe code  -->
  <div id="iframeId" class="widget-code-container mb20">
    {{ iframeCode }}
  </div>
  <button tooltip="Text Copied" tooltip-position="right" tooltip-state='close'
          class="btn btn-primary" @click="clickedCopyToClipboard">
    Copy code for iframe<i class="fa fa-clipboard"></i>
  </button>

  <hr  />

  <!-- iframe preview -->
  <div class="widget-preview-container">

    <div class="responsive-iframe-wrapper" v-html="boundCodeForWidgetPreview"></div>

    <div class="line-horizontal">
      <div class="arrow next">up</div>
      <span class="inside-horizontal-line-label">{{iframeWidth}}px</span>
      <div class="arrow prev">up</div>
    </div>
    <div class="line-vertical" :class="{'line-normal' : normalLine, 'line-small': smallLine, 'line-xsmall': xsmallLine}">
      <div class="arrow up">up</div>
      <span class="inside-vertical-line-label">{{iframeHeight}}px</span>
      <div class="arrow down">up</div>
    </div>
  </div>

</div>
</template>

<script>
import '@/assets/sass/sizeIndicators.scss';

export default {
  name: "WidgetPreview",
  props: {
    formModel: Object
  },
  data() {
    return {
      iframeCode: '',
      normalLine: true,
      smallLine: false,
      xsmallLine: false,
      iframeWidth: 390,
      iframeHeight: 0,
    }
  },
  computed: {
    //if affiliate code is added to the iframe's code parameter name is added in url before the actual code
    showCodeInUrl: function() {
      if (this.formModel.affiliationCode.value !== '') {
        return 'aff_uid=';
      }
      return '';
    },
    //if affiliate code is added to the iframe's code & is added in url to prevent the url from breaking
    separateCodeColorInUrl: function() {
      if (this.formModel.affiliationCode.value !== '') {
        return '&';
      }
      return '';
    },
    // the iframeCode is ready for user to use it inside blog or site
    //on the other hand the iframePreview is only a preview so we dont want to be functional,
    // that s why we never pass affiliate code there
    boundCodeForWidgetPreview: function() {
      this.separationOfIframeDimensions();
      this.iframeCode =
        `<iframe width=\"${this.iframeWidth}\" height=\"${this.iframeHeight}\" scrolling=\"no\" frameborder=\"0\" src=\"https://www.ferryhopper.com/${this.formModel.language.value}/embed/${this.formModel.variant.value}?${this.showCodeInUrl}${this.formModel.affiliationCode.value}${this.separateCodeColorInUrl}color=${this.formModel.removeHashFromColor()}\"></iframe>`
      this.iframePreview = `<iframe scrolling=\"no\" frameborder=\"0\" src=\"https://www.ferryhopper.com/${this.formModel.language.value}/embed/${this.formModel.variant.value}?color=${this.formModel.removeHashFromColor()}\"></iframe>`

      return this.iframePreview;
    },
  },
  methods: {
    //depending on the flavor selected we bind classes in our html and we set the height property in the ifraneCode
    separationOfIframeDimensions() {
      switch (this.formModel.variant.value) {
        case 'simple':
          this.iframeHeight = 410;
          this.normalLine = true;
          this.smallLine = false;
          this.xsmallLine = false;
          break;
        case 'small':
          this.iframeHeight = 350;
          this.normalLine = false;
          this.smallLine = true;
          this.xsmallLine = false;
          break;
        case 'xsmall':
          this.iframeHeight = 310;
          this.normalLine = false;
          this.smallLine = false;
          this.xsmallLine = true;
          break;
      }
    },
    //handle the on click event in copy to clipboard button
    clickedCopyToClipboard() {
      let textToCopy = document.getElementById('iframeId').innerText;
      let temporaryInputElement = document.createElement("input");

      this.copyToClipboard(temporaryInputElement, textToCopy)
      this.showTooltip()

    },
    //method used to set the tooltip-state attribute to open so that the tooltip will show up
    //after a small delay the tooltip hide again
    showTooltip() {
      var action = document.querySelectorAll("[tooltip]");
      action[0].setAttribute("tooltip-state", "open")
      setTimeout(function() {
        action[0].setAttribute("tooltip-state", "close")
      }, 2000);

    },
    //we create a temporary input and we copy inside the innerText we want to copy to clipboard.
    //after we copy it to clipboard we remove the input again from the DOM
    copyToClipboard(temporaryInputElement, textToCopy) {
      temporaryInputElement.type = "text";
      temporaryInputElement.value = textToCopy;
      document.body.appendChild(temporaryInputElement);
      temporaryInputElement.select();
      document.execCommand("Copy");
      document.body.removeChild(temporaryInputElement);
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
