<template>
    <div class="row">
      <!-- action buttons -->
      <div class="col-xs-12 col-sm-3 col-md-3 col-lg-5 text-left">
        <button class="btn btn-primary btn-lg btn-link mb5" v-on:click="createReport()" :disabled="isComponentBusy">
          <span v-if="!isCreatingReport"><i class="fa fa-save"></i> Generate report</span>
          <span v-if="isCreatingReport"><i class="fa fa-spinner fa-spin"></i> Creating...</span>
        </button>
      </div>

      <!-- date changers -->
      <div class="col-xs-12 col-sm-8 col-md-8 col-lg-7 text-right">
        <DaterComponent />
      </div>

      <div class="col-xs-12 col-md-12">
        <div class="card" :class="{ busy: isComponentBusy }" style="padding-bottom:5px;">

          <div style="padding: 5px;" v-if="isAttributionModel">
            <button class="btn btn-fill btn-sm" :class="{ 'btn-danger': isShowingTripsTraveled }"
                    style="margin: 2px;"
                    @click="toggleTripsTraveled(true)">Show trips traveled</button>
            <button class="btn btn-fill btn-sm" :class="{ 'btn-danger': !isShowingTripsTraveled }"
                    style="margin: 2px;"
                    @click="toggleTripsTraveled(false)">Show trips booked</button>
          </div>

          <PaginatedTableComponent
            :maxPage="maxPage"
            :title="isShowingTripsTraveled ? 'Trips Traveled' : 'Trips Booked'"
            :sub-title="dater.activeMonthStr"
            :data="trips.data"
            :columns="trips.columns"
            :headings="trips.headings"
            :currentPage="currentPage"
            :lockList="waitForListFill"
            @changePage="changePage"
          />
        </div>
      </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import Ports from '@/repositories/ports';
import Companies from '@/repositories/companies';
import Dater from '@/repositories/dater'
import DaterComponent from '@/components/shared/DaterComponent.vue'
import PaginatedTableComponent from '@/components/lists/PaginatedTableComponent.vue'
import Api from '@/services/ApiGateway'
import { mapGetters, mapState } from 'vuex';

const filterTrips = (trips) => {
  return trips.map(trip => ({
    ...trip,
    origin: Ports.location(trip.origin) && Ports.location(trip.origin).alias || trip.origin,
    destination: Ports.location(trip.destination) && Ports.location(trip.destination).alias || trip.destination,
    company: Companies.company(trip.company),
    departureDate: trip.departureDate ? dayjs(trip.departureDate).format('DD/MM/YYYY') : undefined,
    bookedDate: trip.createdAt ? dayjs(trip.createdAt).format('DD/MM/YYYY') : undefined,
  }))
};

export default {
  components: {
    PaginatedTableComponent,
    DaterComponent,
  },
  data() {
    return {
      currentPage: 0,
      dater: Dater,
      tripsTraveledList: [],
      tripsBookedList: [],
      isCreatingReport: false,
      paginationSize: window.ferryhopper && window.ferryhopper.pagination_size || 50,
      tableStructure: [],
      isShowingTripsTraveled: true,
      waitForListFill: false,
    }
  },
  watch: {
    // Executes instantly once component comes into view, and each time the date changes
    getActiveDates(previous, current) {
      this.getTripsList(1);
    }
  },
  computed: {
    ...mapState({
      tripsBookedCount: state => state.statisticsModule.tripsBookedCount,
      tripsTraveledCount: state => state.statisticsModule.tripsTraveledCount,
      model: state => state.statisticsModule.model,
    }),
    ...mapGetters({
      getActiveDates: 'datesModule/getActiveDates',
      impersonateUser: 'authentication/impersonateUser',
    }),
    isAttributionModel() {
      return this.model === 'TRAVEL_DATE_ATTRIBUTION'
    },
    tripsList() {
      return (this.isAttributionModel && this.isShowingTripsTraveled)? this.tripsTraveledList : this.tripsBookedList;
    },
    trips() {
      const headings = this.tableStructure.map(c => c.columnName);
      const columns = this.tableStructure.map(c => c.itemProperty);
      return {
        headings: [...headings],
        columns: [...columns],
        data: [...this.tripsList],
      }
    },
    isComponentBusy() {
      return this.isCreatingReport || this.waitForListFill
    },
    maxPage() {
      const totalRowsCount = (this.isAttributionModel && this.isShowingTripsTraveled)? this.tripsTraveledCount : this.tripsBookedCount;
      return Math.floor(totalRowsCount / this.paginationSize)
    }
  },
  methods: {
    createReport() {
      this.isCreatingReport = true
      Api.userReport('trips').then(res => {
        window.open(res.data.url, 'name')
      }).finally(() => this.isCreatingReport = false);
    },
    changePage(page) {
      this.currentPage = page;
      this.getTripsList(this.currentPage + 1);
    },
    toggleTripsTraveled(value) {
      this.isShowingTripsTraveled = value;
      this.changePage(0);
    },
    getTripsList(page) {
      this.waitForListFill = true;
      Api.fetchTripsTablePage(page, this.impersonateUser).then(result => {
        const { model, tripsTraveledCount, tripsTraveled, tripsBooked, tripsBookedCount, tableStructure } = result.data;
        this.tableStructure = tableStructure;
        this.$store.dispatch('statisticsModule/setModel', model);

        this.$store.dispatch('statisticsModule/setTripsTraveledCount', tripsTraveledCount || 0);

        // Only in the case of TRAVEL_DATE_ATTRIBUTION model we can "trust" the tripsBookedCount value
        // In the other cases, this number always equals to the pagination
        if (model === 'TRAVEL_DATE_ATTRIBUTION') {
          this.$store.dispatch('statisticsModule/setTripsBookedCount', tripsBookedCount);
        }

        this.tripsTraveledList = filterTrips(tripsTraveled || []);
        this.tripsBookedList = filterTrips(tripsBooked || []);
        if (model !== 'TRAVEL_DATE_ATTRIBUTION') {
          this.isShowingTripsTraveled = false;
        }
        this.waitForListFill = false;
      }).catch(error => this.waitForListFill = false)
    }
  },
}
</script>
<style>
</style>
