<template>
<nav class="navbar navbar-default">
  <div class="container-fluid">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle" :class="{toggled: showSidebar}" @click="toggleSidebar">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar bar1"></span>
          <span class="icon-bar bar2"></span>
          <span class="icon-bar bar3"></span>
        </button>
      <span class="navbar-brand hidden-sm hidden-md hidden-lg hidden-xl">
        {{ currentRouteName }}
      </span>
    </div>
  </div>
</nav>
</template>
<script>
import Dater from '@/repositories/dater';
import { mapState } from 'vuex';

export default {
  computed: {
    routeName() {
      const {
        name
      } = this.$route
      return this.capitalizeFirstLetter(name)
    }
  },
  data() {
    return {
      activeNotifications: false,
      dater: Dater
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications
    },
    closeDropDown() {
      this.activeNotifications = false
    },
    toggleSidebar() {
      this.$store.dispatch('lockUi/toggleSidebar');
    },
    hideSidebar() {
      this.$store.dispatch('lockUi/hideSidebar');
    }
  },
  computed: {
    ...mapState({
      showSidebar: state => state.lockUi.showSidebar
    }),
    currentRouteName () {
      return this.$route.name;
    }
  }
}
</script>
<style>

</style>
