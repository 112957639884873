<template>
  <div>
    <div class="row">
      <!-- widget generator form (Widget Settings) -->
      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="card">
          <CardHeading
            :title="'Widget Settings'"
            :icon="'fa-settings'"
          ></CardHeading>
          <div class="content">
            <div class="form-group has-feedback">
              <label class="input-labels form-group" for="selectAffiliateCode">Affiliation Code</label>
              <select
                id="selectAffiliateCode"
                class="form-control form-group select-buttons-small"
                v-model="selectedMastercode"
                @change="updateAffiliationCode"
              >
                <option
                  v-for="(code, codeIndex) in masterCodeArray"
                  :value="code"
                  selected=""
                  :key="'code-' + codeIndex"
                >
                  {{ code }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label class="input-labels form-group" for="selectLanguage"
                >Language</label
              >
              <select
                id="selectLanguage"
                class="form-control form-group select-buttons-small"
                v-model="selectedLanguage"
                @change="updateWidgetLocale"
              >
                <option value="el">Greek</option>
                <option value="en" selected="true">English</option>
                <option value="it">Italian</option>
                <option value="fr">French</option>
                <option value="de">German</option>
                <option value="es">Spanish</option>
                <option value="pt">Portuguese</option>
              </select>
            </div>

            <div class="form-group">
              <label class="input-labels form-group block-label">Size</label>
              <WidgetFlavorSelector
                :flavors="widgetFlavors"
                :selection="selectedVariation"
                @change="setWidgetFlavor"
              >
              </WidgetFlavorSelector>
            </div>

            <div class="form-group">
              <div class="check-box">
                <input
                  type="checkbox"
                  id="exampleCheck1"
                  @change="customColorChecked"
                />
                <label
                  class="form-check-label label-after-checkbox"
                  for="exampleCheck1"
                  >Customize Widget Color</label
                >
              </div>
            </div>

            <div
              v-show="showColorInput"
              v-for="(attribute, attributeIndex) in modifiableFields"
              :key="'attribute-' + attributeIndex"
            >

              <SimpleInput
                @onInputChange="inputComponentListening"
                :label="attribute.label"
                :bindAttribute="attribute.type"
                :value="attribute.value"
                :type="attribute.inputType"
              >
              </SimpleInput>
            </div>
          </div>
        </div>
      </div>

      <!-- widget preview -->
      <div class="col-xs-12 col-sm-8 col-md-8 col-lg-6">
        <div class="card">
          <CardHeading :title="'Create affiliation url'"></CardHeading>
          <div class="content">
            <div class="form-group">
              <label class="input-labels">Copy your url here:</label>
              <input class="form-control" type="url" v-model="simpleUrl" />
            </div>

            <button
              tooltip="Text Copied"
              tooltip-position="right"
              tooltip-state="close"
              class="btn btn-primary"
              @click="clickedCopyToClipboard"
            >
              Copy affiliation url <i class="fa fa-clipboard"></i>
            </button>
          </div>
        </div>
        <div class="card">
          <CardHeading :title="'Iframe Url & Preview'" :icon="'fa-preview'"></CardHeading>
          <div class="content">
            <WidgetPreview :formModel="widget"></WidgetPreview>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleInput from '@/components/UIComponents/Inputs/SimpleInput.vue'
import CardHeading from '@/components/UIComponents/Cards/CardHeading.vue'
import { keys as _keys, each as _each } from 'lodash-es';
import { keyToLabel } from '@/repositories/keyToLabel'
import WidgetFormModel from '@/models/WidgetFormModel'
import WidgetPreview from '@/components/UIComponents/WidgetPreview'
import WidgetFlavorSelector from '@/components/shared/WidgetFlavorSelector'
import '@/assets/sass/tooltips.scss'
import { mapGetters } from 'vuex';

export default {
  components: {
    SimpleInput,
    CardHeading,
    WidgetPreview,
    WidgetFlavorSelector
  },
  data() {
    return {
      widget: new WidgetFormModel(),
      simpleUrl: '',
      attributes: [],
      inputCreationFilters: ['variant', 'language', 'affiliationCode'],
      selectedVariation: 'simple',
      selectedLanguage: 'en',
      selectedMastercode: '',
      showColorInput: false,
      widgetFlavors: [
        {
          type: 'simple',
          title: 'Normal',
        },
        {
          type: 'small',
          title: 'Small',
        },
        {
          type: 'xsmall',
          title: 'Extra small',
        },
      ],
    }
  },
  created() {
    this.widget.attributesForComponentInputs()
    this.selectedVariation = this.widget.variant.value
    this.selectedLanguage = this.widget.language.value
    if (this.getAppUser && this.getAppUser.affiliationCodes.length > 0) {
        this.selectedMastercode = this.getAppUser.affiliationCodes[0];
        this.updateAffiliationCode()
      }
  },
  watch: {
    getAppUser(oldValue, newValue) {
      if (newValue.affiliationCodes.length > 0) {
        this.selectedMastercode = this.getAppUser.affiliationCodes[0];
        this.updateAffiliationCode()
      }
    }
  },
  computed: {
    ...mapGetters({
      getAppUser: 'authentication/getAppUser'
    }),
    simpleUrlWithCode() {
      return `${this.simpleUrl}?aff_uid=${this.selectedMastercode}`
    },
    //used to create the options of affiliation Code select
    masterCodeArray: function() {
      return this.getAppUser.affiliationCodes
    },
    modifiableFields: function() {
      let keys = _keys(this.widget)
      let objectName = 'attributes'
      //pass the array with the names of the model settings,
      // the name of the object we want to pass the inputs to,
      //and filters' array if there is one
      this.createInputsAndProps(keys, objectName, this.inputCreationFilters)

      return this.attributes
    },
  },
  methods: {
    inputComponentListening(value, type) {
      this.widget[type].value = value
    },
    //create object for simpleInput component props
    createAttributeObj(index, name, type, inputType) {
      let createdObject = {
        label: index,
        value: name,
        type: type,
        inputType: inputType,
      }

      return createdObject
    },
    createInputsAndProps: function(keys, theObjectName, filtersArray = ['']) {
      let attributes = []
      _each(keys, key => {
        let labelFormatReady = keyToLabel(key)
        let attributeObj = this.createAttributeObj(
          labelFormatReady,
          this.widget[key].value,
          key,
          this.widget[key].inputType
        )
        if (!filtersArray.includes(key)) {
          attributes.push(attributeObj)
        }
      })
      this[theObjectName] = attributes;
    },
    //listen for the WidgetFlavorSelector component emit
    setWidgetFlavor: function(value) {
      this.selectedVariation = value
      this.widget.variant.value = value
    },
    updateWidgetLocale: function() {
      this.widget.language.value = this.selectedLanguage
    },
    updateAffiliationCode: function() {
      this.widget.affiliationCode.value = this.selectedMastercode
    },
    // if the checkbox isn t checked ferryhopper color is set as default theme color for the widget
    // else affiliates color is set as default and an input shows up so that the user can change theme color of the widget
    customColorChecked: function() {
      this.widget.themeColor.value = this.getAppUser.color
      if (this.showColorInput === false) {
        this.showColorInput = true
      } else {
        this.showColorInput = false
        this.widget.resetColorOnly()
      }
    },
    //handle the on click event in copy to clipboard button
    clickedCopyToClipboard() {
      if (this.simpleUrl === '') {
        this.$store.dispatch('notificationsModule/errorNotify', 'You need to add your url before copying')
        return
      }
      let textToCopy = this.simpleUrlWithCode
      let temporaryInputElement = document.createElement('input')
      this.copyToClipboard(temporaryInputElement, textToCopy)
      let action = document.querySelectorAll('[tooltip]')
      action[0].setAttribute('tooltip-state', 'open')
      setTimeout(() => action[0].setAttribute('tooltip-state', 'close'), 1000);
    },
    //we create a temporary input and we copy inside the innerText we want to copy to clipboard.
    //after we copy it to clipboard we remove the input again from the DOM
    copyToClipboard(temporaryInputElement, textToCopy) {
      temporaryInputElement.type = 'text'
      temporaryInputElement.value = textToCopy
      document.body.appendChild(temporaryInputElement)
      temporaryInputElement.select()
      document.execCommand('Copy')
      document.body.removeChild(temporaryInputElement)
    },
  },
}
</script>
