<template>
  <div class="notifications">
    <transition-group name="list" tag="span">
      <notification v-for="(notification,index) in notifications" 
        :key="'notification-' + index" 
        :message="notification.message" 
        :type="notification.type" 
        @on-close="removeNotification(index)">  
      </notification>
    </transition-group>
  
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
  import Notification from './Notification.vue'

  export default {
    components: {
      Notification
    },
    computed: {
      ...mapState({
        notifications: state => state.notificationsModule.notifications
      }),
      ...mapGetters({
        getNotifications: 'notificationsModule/getNotifications'
      })
    },
    methods: {
      removeNotification (index) {
        this.$store.dispatch('notificationsModule/removeNotification', index);
      }
    }
  }

</script>
<style lang="scss">
  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  
  .list-enter-active,
  .list-leave-active {
    transition: all 1s;
  }
  
  .list-enter-from,
  .list-leave-to  
  {
    opacity: 0;
    transform: translateY(-30px);
  }
</style>
