
<template>
  <div class="row">
    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-5 text-left"></div>
    <div class="col-xs-12 col-sm-8 col-md-8 col-lg-7 text-right negate-float">
      <DaterComponent />
    </div>

    <div class="col-sm-4" v-for="(card, index) in statsCards" :key="`stats-card-${index}`">
      <StatsCard :class="{ busy: isWaiting }" :loading="isWaiting">
        <template v-slot:header>
          <div class="icon-big" :class="`icon-${card.type}`">
            <i :class="card.icon"></i>
          </div>
        </template>
        <template v-slot:content>
          <div class="numbers">
            <p>{{ card.title }}</p>
            {{ card.value }}
          </div>
        </template>
        <template v-slot:extra v-if="card.extra">
          <table class="table stats-table">
            <tbody>
              <tr v-for="(item, itemIndex) in card.extra" :key="`stats-card-${index}-${itemIndex}`">
                <td class="name">{{ item.name }}</td>
                <td class="value">{{ item.value }}</td>
              </tr>
            </tbody>
          </table>
        </template>
      </StatsCard>
    </div>

    <div class="col-xs-12">
      <RollingEarningsGraph :loading="isWaiting" />
    </div>
  </div>
</template>

<script>

import DaterComponent from '@/components/shared/DaterComponent.vue'
import StatsCard from '@/components/UIComponents/Cards/StatsCard.vue'
import RollingEarningsGraph from './RollingEarningsGraph'
import { mapGetters, mapState } from 'vuex'
import Api from '../services/ApiGateway'


export default {
  components: {
    StatsCard,
    DaterComponent,
    RollingEarningsGraph,
  },
  data() {
    return {
      isWaiting: true,
    }
  },
  watch: {
    // Executes instantly once component comes into view, and each time the date changes
    getActiveDates(previous, current) {
      this.getOverviewStats();
    }
  },
  computed: {
    ...mapState({
      currentMonthRevenues: state => state.statisticsModule.currentMonthRevenues,
      revenuesBreakdown: state => state.statisticsModule.revenuesBreakdown,
      averageBookingsAmount: state => state.statisticsModule.averageBookingsAmount,
      tripsBookedCount: state => state.statisticsModule.tripsBookedCount,
      ticketsBookedCount: state => state.statisticsModule.ticketsBookedCount,
      transactionsCount: state => state.statisticsModule.transactionsCount,
      affiliationAmounts: state => state.statisticsModule.affiliationAmounts,
      bookingsPerCode: state => state.statisticsModule.bookingsPerCode,
      tripsPerCode: state => state.statisticsModule.tripsPerCode,
      tripsTraveledCount: state => state.statisticsModule.tripsTraveledCount,
      transactionsBreakdown: state => state.statisticsModule.transactionsBreakdown,
      model: state => state.statisticsModule.model,
    }),
    ...mapGetters({
      getActiveDates: 'datesModule/getActiveDates',
      impersonateUser: 'authentication/impersonateUser',
    }),
    statsCards() {
      return [
        {
          visible: true,
          type: 'success',
          icon: 'fa fa-euro',
          title: 'Affiliation Revenues',
          value: this.currentMonthRevenues,
          extra: this.revenuesBreakdown,
        },
        {
          visible: true,
          type: 'warning',
          icon: 'fa fa-shopping-cart',
          title: 'Bookings Performed',
          value: this.transactionsCount,
          extra: this.bookingsPerCode,
        },
        {
          visible: this.model === 'TRAVEL_DATE_ATTRIBUTION',
          type: 'danger',
          icon: 'fa fa-bar-chart',
          title: 'Trips Traveled',
          value: this.tripsTraveledCount,
          extra: this.tripsPerCode,
        },
        {
          visible: this.model === 'BOOKING_DATE_ATTRIBUTION',
          type: 'warning',
          icon: 'fa fa-euro',
          title: 'Average order value',
          value: this.averageBookingsAmount,
          extra: this.affiliationAmounts,
        },
        {
          visible: this.model === 'BOOKING_DATE_ATTRIBUTION',
          type: 'danger',
          icon: 'fa fa-bar-chart',
          title: 'Trips Booked',
          value: this.tripsBookedCount
        },
        {
          visible: this.model === 'BOOKING_DATE_ATTRIBUTION',
          type: 'danger',
          icon: 'fa fa-ticket',
          title: 'Tickets Booked',
          value: this.ticketsBookedCount
        },
      ].filter(c => c.visible);
    }
  },
  methods: {
    getOverviewStats() {
      this.isWaiting = true;
      Api.getUserData(this.impersonateUser).then(({ data }) => {
        this.$store.dispatch('statisticsModule/setStatistics', data)
        this.isWaiting = false;
      }).catch(console.log)
    },
  }
}
</script>
