<template>
<div class="content" style="padding: 5px;">
    <ul class="pagination" v-if="maxPage > 0">
        <li>
            <span class="clickable" v-on:click="onPageClick(0)">First</span>
        </li>
        <li v-for="page in pageRange" :class="{ active : page === currentPage}" :key="'page-' + page">
            <span class="clickable"  v-html="page + 1" v-on:click="onPageClick(page)"></span>
        </li>
        <li>
            <span class="clickable" v-on:click="onPageClick(maxPage)">Last</span>
        </li>
    </ul>
</div>
</template>

<script>
export default {
    name: 'PaginatedTablePaginationButtons',
    props: ['currentPage', 'maxPage', 'pageRange'],
    methods: {
        onPageClick(page) {
            this.$emit('onPageClick', page);
        }
    }
}
</script>

<style scoped>
.clickable {
    cursor: pointer;
}
</style>