
import Dater from '@/repositories/dater'

export default {
  namespaced: true,
  state: {
    activeDates: Dater.getFormattedArray()
  },
  getters: {
    getActiveDates: (state) => state.activeDates
  },
  mutations: {
    setActiveDates(state, {from, to}) {
      state.activeDates = {
        from, to
      }
    }
  },
  actions: {
    setActiveDates({ state, commit }, { from, to }) {
      commit('setActiveDates', { from, to })
    }
  }
}
