<template>
<div class="widget-flavor-selector">
  <span v-for="flavor in flavors" :key="'widget-flavor-' + flavor.type">
    <span class="flavor-toggle"
      :class="{'active' : flavor.type === selection }"
      @click="onFlavorClick(flavor.type)" >{{ flavor.title }}</span>
  </span>
</div>
</template>

<script>
export default {
  name: 'widget-flavor-selector',
  props: [
    'selection',
    'flavors'
  ],
  methods: {
    onFlavorClick : function (flavor) {
      this.$emit('change', flavor)
    }
  }
}
</script>
