<template>
  <div :class="sidebarClasses" :data-background-color="backgroundColor" :data-active-color="activeColor">
    <div class="sidebar-wrapper" id="style-3">
      <div class="logo">
        <a href="#" class="simple-text">
            <div class="logo-img">
                <img src="https://images.ferryhopper.com/assets/logo/ferryhopper-marker-32.png" alt="">
            </div>
            Ferryhopper
        </a>
      </div>
      <slot></slot>

      <div style="display: flex; flex-direction: column; justify-content: space-between;">
        <ul :class="navClasses">
          <li>
            <router-link v-for="link in simpleLinks" :to="link.path" :ref="link.name" :key="link.path">
                <i :class="link.icon"></i>
                <p>{{link.name}}</p>
            </router-link>
          </li>

          <li>
            <router-link v-for="link in protectedLinks" :to="link.path" :ref="link.name" :key="link.path">
              <i :class="link.icon"></i>
              <p>{{link.name}}</p>
            </router-link>
          </li>
        </ul>
        <button class="btn logout-btn btn-link" v-on:click="logout()">Logout</button>
      </div>
      <moving-arrow :move-y="arrowMovePx" />
    </div>
  </div>
</template>
<script>

import MovingArrow from './MovingArrow.vue'
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    type: {
      type: String,
      default: 'sidebar',
      validator: (value) => {
        let acceptedValues = ['sidebar', 'navbar']
        return acceptedValues.indexOf(value) !== -1
      }
    },
    backgroundColor: {
      type: String,
      default: 'black',
      validator: (value) => {
        let acceptedValues = ['white', 'black', 'darkblue']
        return acceptedValues.indexOf(value) !== -1
      }
    },
    activeColor: {
      type: String,
      default: 'success',
      validator: (value) => {
        let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
        return acceptedValues.indexOf(value) !== -1
      }
    }
  },
  components: {
    MovingArrow
  },
  computed: {
    ...mapState({
      sidebarLinks: state => state.lockUi.sidebarLinks
    }),
    ...mapGetters({
      getAppUser: 'authentication/getAppUser'
    }),
    simpleLinks () {
      return this.sidebarLinks.filter(o => !o.admin);
    },
    protectedLinks () {
      if (!this.getAppUser.isAdmin) {
        return [];
      }
      return this.sidebarLinks.filter(o => o.admin);
    },
    sidebarClasses () {
      if (this.type === 'sidebar') {
        return 'sidebar'
      } else {
        return 'collapse navbar-collapse off-canvas-sidebar'
      }
    },
    navClasses () {
      if (this.type === 'sidebar') {
        return 'nav'
      } else {
        return 'nav navbar-nav'
      }
    },
    /**
     * Styles to animate the arrow near the current active sidebar link
     * @returns {{transform: string}}
     */
    arrowMovePx () {
      return this.linkHeight * this.activeLinkIndex
    }
  },
  data () {
    return {
      linkHeight: 60,
      activeLinkIndex: 0,
      windowWidth: 0,
      isWindows: false,
      hasAutoHeight: false
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('authentication/logOutAction').then(() => {
        this.$router.push({ name: 'home' });
      });
    },
    findActiveLink () {
      this.sidebarLinks.find((element, index) => {

        let found = element.path === this.$route.path;

        if (found) {
          this.activeLinkIndex = index;
        }

        return found;
      })
    }
  },
  mounted () {
    this.findActiveLink()
  },
  watch: {
    $route: function (newRoute, oldRoute) {
      this.findActiveLink()
    }
  }
}
</script>

<style>

</style>
