<template>
  <div class="card no-footer">      
    <CardHeading :title="title" :icon="icon"></CardHeading>
    <div class="content">
        <LoadingListOverlay v-if="loading"></LoadingListOverlay>
        <slot name="content" v-else>

        </slot>
    </div>    
  </div>
</template>
<script>
import LoadingListOverlay from '@/components/shared/LoadingListOverlayComponent'
import CardHeading from '@/components/UIComponents/Cards/CardHeading.vue'
export default {
  name: 'SimpleCard',
  components: {
    LoadingListOverlay,
    CardHeading
  },
  props: ['loading', 'icon', 'title'],
}
</script>
<style>
</style>
