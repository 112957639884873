<template>
  <div class="card-loading-spinner">
    <div class="loading-spinner-circle-spinner">
        <div class="circle"></div>
        <div class="orbit"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingListOverlay',
}
</script>
