function WidgetFormModel() {
  this.attributesForComponentInputs()
}

//model for form used in widget generator
WidgetFormModel.prototype = {

  //function used to remove hash from hex color value so that can be passed in widget parameters
  removeHashFromColor() {
    let hex = this.themeColor.value

    return hex.replace('#', '')
  },
  //reset color in ferryhopper color
  resetColorOnly() {
    this.themeColor = {
      value: '#01C3A7',
      inputType: 'color',
    }
  },
  //model made to fit the simpleInput component props
  attributesForComponentInputs: function() {
    this.affiliationCode = {
      value: '',
      inputType: 'text',
    }
    this.themeColor = {
      value: '#01C3A7',
      inputType: 'color',
    }
    this.language = {
      value: 'el',
      inputType: 'text',
    }
    this.variant = {
      value: 'simple',
      inputType: 'text',
    }
  },
}

export default WidgetFormModel
