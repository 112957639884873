import { hex } from '@/services/custom'

function AffiliateModel(apiData) {

    this.name = '';
    this.contactEmail = '';
    this.masterCode = this.code;
    this.accessCode = '';
    this.affiliationModel = 'SEMIFIXED';
    this.serviceFeeMode = 'ABSOLUTE';
    this.color = hex();
    this.fixedCommission = 0;
    this.feeCommission = 0;
    this.serviceFee = 0;
    this.dataUrl = '';

    if (apiData) {
        const {
            code,
            name,
            contactEmail,
            color,
            dataUrl,
            password,
            masterCode: masterCodesArray,
            isDeactivated
        } = apiData.affiliate;

        const {
            feeCommission,
            fixedCommission,
            affiliationModel,
            serviceFeeValue,
            serviceFeeMode,
        } = apiData;


        this.name = name;
        this.color = color;
        this.code = code;
        this.contactEmail = contactEmail;
        this.fixedCommission = fixedCommission;
        this.feeCommission = feeCommission;
        this.affiliationModel = affiliationModel;
        this.serviceFeeMode = serviceFeeMode;
        this.password = password;
        this.isDeactivated = isDeactivated || false;
        const appliedFee = serviceFeeMode === 'ABSOLUTE' ? (serviceFeeValue / 100) : serviceFeeValue;
        this.serviceFee = appliedFee;
        this.dataUrl = dataUrl;
        this.masterCode = masterCodesArray.toString();
    }
}

export default AffiliateModel;