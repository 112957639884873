<template>
<div class="header">
  <div class="row">
    <div class="col-xs-5">
      <div class="icon-big icon-warning">
        <i class="fa" :class="icon"></i>
      </div>
    </div>
    <div class="col-xs-7">
      <div class="numbers">
        <p>{{ title }}</p>
        {{ value }}
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'CardHeading',
  props: ['title', 'icon', 'value']
}
</script>
