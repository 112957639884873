<template>
  <span class="label aff-bubble" :style="colorStyle">{{ code }}</span>
</template>

<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
  affiliate: Object,
  code: String
})


const colorStyle = computed(() => {
  return {
    backgroundColor: props.affiliate.color
  }
})
</script>
