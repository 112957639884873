import { object, string, number } from 'yup';

export const schema = object({
    name: string()
        .min(3)
        .max(50)
        .required(),

    password: string()
        .min(8)
        .max(50)
        .required(),

    contactEmail: string()
        .email()
        .required(),

    masterCode: string()
        .min(5)
        .max(500)
        .required(),

    fixedCommission: number()
        .required(),
        
    feeCommission: number()
        .required(),
                
    affiliationModel: string()
        .required(),

    color: string(),
});