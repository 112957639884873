const notificationsModule = {
    namespaced: true,
    state: {
        notifications: [],
        test: 'test'
    },

    getters: {
        getNotifications: state => state.notifications
    },

    mutations: {
        removeNotification(state, index) {
            state.notifications.splice(index, 1)
        },
        successNotify(state, message) {
            state.notifications.push({
                message: message,
                type: 'success'
            });
        },
        errorNotify(state, message) {
            state.notifications.push({
                message: message,
                type: 'danger'
            });
        },
        warningNotify(state, message) {
            state.notifications.push({
                message: message,
                type: 'warning'
            });
        }
    },

    actions: {
        removeNotification({ commit }, index) {
            commit('removeNotification', index)
        },
        successNotify({ commit }, message) {
            commit('successNotify', message)
        },
        errorNotify({ commit }, message) {
            commit('errorNotify', message)
        },
        warningNotify({ commit }, message) {
            commit('warningNotify', message)
        }
    }
}

export default notificationsModule;