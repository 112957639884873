<template>
    <div class="login-page">
        <div class="container">
            <div class="row">
                    <div class="banner col-xs-6">
                        <img class="ferry-logo" src="https://images.ferryhopper.com/assets/logo/ferryhopper-logo-white-medium.png"/>
                        <h1 class="center-block">Affiliates</h1>
                    </div>
                    <div class="menu-container col-xs-6">
                        <BurgerMenu :menu-links="menuLinks" />
                    </div>
                <div class="col-xs-12 col-md-12">
                    <h2 class="par-title">Ferryhopper at a glance</h2>
                    <p>Ferryhopper is the only online ferry booking platform that provides the possibility of searching connections from island A to island B,
                      even if these islands are not directly connected with a ferry. In other words, we return results not only for direct ferry connections,
                      but also for indirect connections with one or more stops. This can be extremely useful when traveling in Greece and Spain,
                      where trying to find a way to get from one port to another is often a logistical nightmare. We also offer the easiest way to book ferry tickets,
                      as all information is displayed clearly on our website, without annoying spam that distracts users. And last but not least,
                      we always ensure that our customers receive the best possible support before, during and after their trip.
                    </p>
                  <p>
                    At the moment, we are collaborating with more than 50 ferry companies - including all major Greek and Spanish operators - and offer tickets for more than 240 ports in Greece,
                    Italy, Spain, Turkey, Albania, France, Algeria, Morocco, Tunisia and Malta.
                  </p>
                </div>
                <div class="col-md-12 col-xs-12">
                    <h2 class="par-title">Why Ferryhopper’s affiliate program?</h2>
                    <p>Sign up to our affiliate program to allow your users to book ferry tickets through your website.
                      Provide a complete service to your audience and monetize your content! </p>
                    <ul style="text-align: left;font-size: 16px">
                        <li>We provide top-notch customer support to your audience. Keeping travelers satisfied is our number one priority,
                          as you can see from our <a href="https://www.trustpilot.com/review/ferryhopper.com" target="_blank" rel="noopener nofollow"> online reviews</a>.
                        </li>
                        <li>We help you increase your revenue. We pay our partners commission for every booking made on Ferryhopper coming from their website.
                        </li>
                        <li>We offer easy integration. Ferryhopper provides several widgets that help increase your conversion rate. You can see all our integrations by logging in to our affiliates platform.
                        </li>
                        <li>We provide detailed administration pages. We offer easy online access to information about each booking made through your website. You can also see useful statistics, such as best-performing ferry routes, top destinations and best days to book ferry tickets.
                        </li>
                    </ul>
                </div>

                <div class="col-md-12 col-xs-12">
                    <h2 class="par-title">What are the advantages of our affiliate integration?</h2>
                  <p>Ferryhopper’s affiliate integration has several benefits for you:</p>
                    <ul style="text-align: left;font-size: 16px">
                        <li>No setup costs</li>
                        <li>Embeddable ferry booking widget</li>
                        <li>Embeddable widget for displaying live schedules and prices between two destinations</li>
                        <li>Simple deep link integration with prefilled ports</li>
                        <li>Fair cookie policy</li>
                    </ul>
                  <p>All our widgets can be easily customized according to your needs and your website’s aesthetic!</p>
                </div>
                <div class="col-md-12 col-xs-12">
                    <h2 class="par-title">How does our affiliate program work?</h2>
                    <p>It’s really simple! After you sign up, we generate an affiliate identity that you can add anywhere on your website as a link, banner or widget. When someone books a ferry ticket through your website, you can see your revenue in real time by logging in to your account. Your commission is transferred to your bank account regularly.
                    </p>
                </div>
                <div class="col-md-12 col-xs-12">
                    <h2 class="par-title">Want to join?</h2>
                    <p>It’s easy! Contact us and a member of our team will get back to you within a few days to proceed with your registration.
                    </p>
                    <a href="mailto:affiliates.signup@ferryhopper.com">
                        <button class="btn btn-small-center pull-left">
                            Join Now
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import BurgerMenu from '@/components/shared/BurgerMenu.vue';

const menuLinks = [
  {
    linkName: 'Home',
    path: '/home',
    internalLink: true,
  },
  {
    linkName: 'About',
    path: '/about',
    internalLink: true,
  },
  {
    linkName: 'Contact us',
    path: 'https://www.ferryhopper.com/en/contact',
    internalLink: false,
  },
];
</script>
