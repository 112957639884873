
function CompaniesRepository() {
  this.companiesMap = {
    'MOCK': {
      'CompanyName': 'Mock Ferries',
    }
  }
}

CompaniesRepository.prototype.initialize = function({ companies }) {
  companies.forEach((company) => {
    this.companiesMap[company.CompanyAbbr] = company
  })
}

CompaniesRepository.prototype.company = function(companyAbbr) {
  let companyObject = this.companiesMap[companyAbbr]
  return (companyObject && companyObject.CompanyName) || companyAbbr
}

export default new CompaniesRepository()
