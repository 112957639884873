import authentication from './modules/authentication'
import lockUi from './modules/lockUi'
import bookingPagination from './modules/bookingPagination'
import datesModule from './modules/datesModule'
import statisticsModule from './modules/statisticsModule'
import notificationsModule from './modules/notificationsModule'
import { createStore as _createStore } from 'vuex'

const createStore = () => {
  return _createStore({
    modules: {
      statisticsModule,
      authentication,
      lockUi,
      bookingPagination,
      datesModule,
      notificationsModule
    }
  })
}

const store = createStore();

export default store;
