<template>
  <div class="wrapper">
    <SideBar type="sidebar"></SideBar>
    <div class="main-panel">      
      <Navbar />
      <DashboardContent />
      <Footer />
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/UIComponents/SidebarPlugin/SideBar.vue'
import Navbar from '@/components/shared/Navbar.vue'
import Footer from '@/components/shared/Footer.vue'
import DashboardContent from './DashboardContent.vue'
import { mapState } from 'vuex'

  export default {
    components: {
      Navbar,
      Footer,
      DashboardContent,
      SideBar
    },
    ...mapState({
      sidebarLinks: state => state.lockUi.sidebarLinks
    })
  }

</script>
