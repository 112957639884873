<template>
  <div class="login-page">
    <div class="container">
      <LoginHeader />
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <LoginIntroText />
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="login-box">
            <div v-if="!isSessionExpired">
              <h4>Already a member?</h4>
              <p>Enter your username and password to log in to your account.</p>
            </div>
            <h4 v-else>You have been signed out</h4>

            <form @submit.prevent="login" class="LoginForm">
              <div class="form-group has-feedback">
                <input class="form-control login-input" type="text" v-model="username" placeholder="Username" />
                <i class="fa fa-user form-control-feedback"></i>
              </div>
              <div class="form-group has-feedback">
                <input class="form-control login-input" type="password" v-model="password" placeholder="Access code" />
                <i class="fa fa-lock form-control-feedback"></i>
              </div>
              <div class="clearfix">
                <button class="btn btn-danger btn-login pull-right btn-fill" type="submit" :disabled="isWaiting">
                  <span v-if="!isWaiting">Log in</span>
                  <span v-else><i class="fa fa-spinner fa-spin"></i> Please wait</span>
                </button>
              </div>
            </form>

            <p class="login-msg text-danger text-center" style="margin-top: 20px;" v-if="isSessionExpired && (!loginError)">
              <small class="text-danger">Your session has expired. Please sign in again.</small>
            </p>

            <p v-if="loginError" class="mt20">
              <small class="text-danger">{{ loginErrorMessage }}</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import LoginHeader from './LoginHeader.vue'
import LoginIntroText from './LoginIntroText.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import ApiGateway from '../../../services/ApiGateway'

const store = useStore()
const router = useRouter()

let username = localStorage.getItem('fh_user_name') || ''
let password = ''
const isWaiting = ref(false)
const loginError = ref(false)
const loginErrorMessage = ref('');

const isSessionExpired = store.getters['authentication/isSessionExpired']

const login = async () => {
  isWaiting.value = true
  loginError.value = false

  try {
    const { data: loginData } = await ApiGateway.loginCheck(username, password);
    await store.dispatch('authentication/logInAction', loginData)
    await router.push('admin/overview')
  } catch (error) {
    loginError.value = true
    loginErrorMessage.value = error && error.message || 'An unknown error has occured';
  } finally {
    isWaiting.value = false
  }
}
</script>
