import dayjs from 'dayjs'
import { cloneDeep as _cloneDeep } from 'lodash-es'

// ctor
function Dater() {
  this.activeDate = dayjs().startOf('month')
  /**
   * ! we add +1 for our implementation cause moment.js starts months from 0
   */
  this.activeDateMonth = this.activeDate.month() + 1
  this.reset()
}


// reset dater, to dates and date strings
Dater.prototype.reset = function() {
  var dateClonePrev = _cloneDeep(this.activeDate)
  var dateCloneNext = _cloneDeep(this.activeDate)

  this.activeMonthStr = this.activeDate.format('MMMM YYYY')

  this.prevMonthStr = dateClonePrev.subtract(1, 'months')
  this.nextMonthStr = dateCloneNext.add(1, 'months')

  this.startDate = this.activeDate
  this.endDate = dayjs(this.activeDate).endOf('month')
}

// return formated array of dates for usage in API calls
Dater.prototype.getFormattedArray = function() {
  return {
    from: this.startDate.format('YYYYMMDD'),
    to: this.endDate.format('YYYYMMDD'),
  }
}

// take the date from datepicker
Dater.prototype.getDate = function(dateValue) {
  this.activeDate = dayjs(dateValue)
  this.startDate = this.activeDate
  // we need this for chart styling so that the active month can be shown
  this.activeDateMonth = this.activeDate.month() + 1
  this.reset()
}


// take current year and previous month
Dater.prototype.currentYearPreviousMonth = function() {
  let startDate = this.startDate.clone()
  startDate.subtract(1, 'month')
  let month = startDate.format('MM')
  let year = startDate.format('YYYY')

  let dateObject = {
    month: month,
    year: year,
  }

  return dateObject
}

// find the dates that will be displayed as disabled
Dater.prototype.disabledDatesInDatepicker = function() {
  const today = (this.activeDate = dayjs().startOf('month'))

  return {
    to: new Date(2018, 2, 1),
    from: new Date(today),
  }
}

export default new Dater()
