const bookingsPaginationModule = {
    namespaced: true,
    state: {
        currentBookingListsPage: 0,
        bookingLockList: false
    },
    mutations: {
        changePageBookings(state, page) {
            state.currentBookingListsPage = page;
        },
        resetPageBookings(state) {
            state.currentBookingListsPage = 0;
        },
        lockListBooking(state) {
            state.bookingLockList = true;
        },
        unlockListBooking(state) {
            state.bookingLockList = false;
        }
    },
    actions: {
        changeBookingsPageAction({ commit }, page) {
            commit('changePageBookings', page)
        } ,
        resetBookingsPageAction({commit}) {
            commit('resetPageBookings')
        },
        lockBookingList({commit}) {
            commit('lockListBooking')
        },
        unlockBookingList({commit}) {
            commit('unlockListBooking')
        }
    }
}

export default bookingsPaginationModule