<template>
<StatsCard :loading="loading">
    <template v-slot:header>
        <span class="icon-big">
            <i class="fa fa-eur"></i>
        </span>
    </template>
    <template v-slot:content>
        <div class="numbers">
            <p>12 Months Rolling Affiliation Revenues</p>
            <span style="font-size: 0.8em;">Total: {{ totalEarnings }}</span>
        </div>
    </template>
    <template v-slot:extra>
        <GChart type="ColumnChart" :data="chartData" :options="columnChartOptions" />
    </template>
</StatsCard>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import StatsCard from '@/components/UIComponents/Cards/StatsCard.vue'
import { GChart } from 'vue-google-charts'

const chartHeader = [
    'Month',
    'Earnings',
    { role: 'style' },
    { role: 'tooltip', p: { html: true } },
]

const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
]

const euroFormat = Intl.NumberFormat('en-DE', {
  style: 'currency',
  currency: 'EUR'
});

const createTooltip = ({ month, year, amount }) => {
    return `<div class="chart-tooltip-content">
  <small>${month}/${year}</small> <span>${euroFormat.format(amount)}</span>
  </div>`
}


export default {
    name: 'RollingEarningsGraph',
    components: {
        StatsCard,
        GChart,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        columnChartOptions: {
            animation: {
                duration: 500,
                easing: 'linear',
                startup: true,
            },
            chartArea: {
                backgroundColor: 'transparent',
                width: '85%',
                height: '90%',
            },
            legend: {
                position: 'none',
            },
            bars: 'vertical',
            bar: { groupWidth: '88%' },
            vAxis: {
                baselineColor: 'transparent',
                gridlines: {
                    color: '#ededed',
                    count: 4,
                },
                format: '# €',
                textStyle: {
                    color: '#a2a2a2',
                },
            },
            hAxis: {
                baselineColor: 'transparent',
                textStyle: {
                    color: '#a2a2a2',
                },
            },
            colors: ['#01C3A7'],
            fontName: 'Montserrat',
            fontSize: '14',
            height: 400,
            backgroundColor: 'transparent',
            tooltip: { isHtml: true },
        },
    }),
    computed: {
        ...mapState({
            rollingMonthlyEarnings: state => state.statisticsModule.rollingMonthlyEarnings,
        }),
        ...mapGetters({
            rollingEarningsTotal: 'statisticsModule/rollingEarningsTotal',
        }),
        totalEarnings() {
            return euroFormat.format(this.rollingEarningsTotal)
        },
        chartData() {
            return this.rollingMonthlyEarnings.reduce((acc, monthlyEarnings) => {
                return [
                    ...acc,
                    [
                        monthNames[monthlyEarnings.month - 1],
                        monthlyEarnings.amount,
                        `color: #01C3A7;opacity: 0.9`,
                        createTooltip(monthlyEarnings),
                    ]
                ];
            }, [chartHeader])
        }
    },
}
</script>