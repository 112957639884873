<template>
  <div class="hover-card info-card">
    <div class="title-box clearfix">
      <span class="title-icon">
        <i :class="['fa fa-sm fa-area-chart', iconType]" style="color:#01C3A7"></i> {{ title }}
      </span>
    </div>
    <i class="fa fa-lg fa-times close-icon clickable-element" aria-hidden="true" @click.stop="close"></i>

    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>


<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  title: String,
  iconType: String
})

const emit = defineEmits(['onPopupClose'])

const close = () => emit('onPopupClose');
</script>

<style scoped>
.title-box {
  font-size: 18px;
  font-weight: 500;
  color: #01C3A7;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}

.info-card {
  width: 400px;
  height: auto;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1), 1px 1px rgba(0, 0, 0, 0.15);
}

.hover-card {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
}

.close-icon {
  position: absolute;
  right: 18px;
  top: 18px;
}

.clickable-element {
  color: #01C3A7 !important;
  cursor: pointer;
}

.clickable-element:hover {
    color: #005e50 !important;
}

.title-icon {
  display: inline-block;
  margin-left: 16px;
  margin-right: 8px;
}
</style>
