<template>
  <div>
    <InfoPopUp :title="'Earnings Breakdown'" :iconType="icon" @onPopupClose="onHidePerformance">
      <template v-slot:content>
        <table class="table table-contensed">
          <tbody>
          <tr v-for="item in revenuesBreakdown">
            <td>{{ item.name }}</td>
            <td class="value">{{ affiliationRoundingAmounts(item.value) }} &euro;</td>
          </tr>
          </tbody>
        </table>
      </template>
    </InfoPopUp>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import InfoPopUp from '@/components/shared/InfoPopUp.vue'
import { onClickOutside } from '@vueuse/core'
import { affiliationRoundingAmounts } from '../../filters'
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  revenuesBreakdown: Array
})

const emit = defineEmits(['onHidePopUp'])

const icon = 'fa-area-chart'

const onHidePerformance = () => emit('onHidePopUp')

const target = ref(null)
onClickOutside(target, (event) => emit('onHidePopUp'))
</script>

