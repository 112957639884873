import Api from '@/services/ApiGateway'

const anonymousUser =  {
  name: '',
  code: '',
  color: '',
  isAdmin: false,
  isImpersonating: false,
  impersonateUser: undefined,
  affiliationCodes: ['']
};

const authenticationModule = {
  namespaced: true,
  state: {
    isLoggedIn: false,
    user: anonymousUser,
    hasStartedSession: false
  },
  getters: {
    getAppUser: (state) => state.user,
    isSessionExpired: (state) => state.hasStartedSession && (!state.isLoggedIn),
    isImpersonating: (state) => state.user.isImpersonating,
    impersonateUser: (state) => state.user.impersonateUser
  },
  mutations: {
    sessionStart(state, { name, code, color, isAdmin, masterCode, firebaseInstance}) {
      state.user = {
        name,
        code,
        color,
        isAdmin,
        affiliationCodes: masterCode,
        token: firebaseInstance.token
      }
      
      state.isLoggedIn = true;
      state.hasStartedSession = true;
      localStorage.setItem('fh_user_name', name)
      localStorage.setItem('fh_user_token', firebaseInstance.token);
    },
    sessionExpired(state) {
      state.isLoggedIn = false;
    },
    sessionEnd(state) {
      state.user = anonymousUser;
      state.isLoggedIn = false;
      state.hasStartedSession = false;
    },
    startImpersonation(state, impersonateUser) {
      if (!state.user.isAdmin) {
        return;
      }
      state.user.isImpersonating = true;
      state.user.impersonateUser = impersonateUser;
    },
    stopImpersonation(state) {
      state.user.isImpersonating = false;
      state.user.impersonateUser = undefined;
    }
  },
  actions: {
    startImpersonation({ commit }, impersonateUser) {
      commit('startImpersonation', impersonateUser)
    },
    stopImpersonation({ commit }) {
      commit('stopImpersonation')
    },
    logInAction({ commit }, { user }) {
      commit('sessionStart', { ...user });
    },
    logOutAction({ commit }) {
      return new Promise((resolve, reject) => {
        Api.logout()
          .then(() => {
            commit('sessionEnd')
            resolve(true)
          })
          .catch(() => reject(false))
      })
    },
    validateActiveSession({ state, commit }) {
      return new Promise((resolve, reject) => {
        Api.checkSessionLogin()
          .then(({ data }) => { 
            const { user } = data
            
            if (!state.hasStartedSession) {
              commit('sessionStart', { ...user });
            }
            resolve(true); 
          }).catch((e) => {
            if (state.hasStartedSession) {
              commit('sessionExpired');
            }
            reject(false)
          })
      })
    },
  },
}

export default authenticationModule
