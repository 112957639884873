import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
import Login from '@/components/Pages/Login/Login.vue'

// GeneralViews
import ErrorPage from '@/components/shared/404.vue'
import About from '@/components/shared/About.vue'

// Admin pages
import Overview from '@/views/Overview.vue'
import BookingsList from '@/views/BookingsList.vue'
import TripsList from '@/views/TripsList.vue'
import UserRegistration from "../views/UserRegistration";
import AffiliatesList from "../views/AffiliatesList";
import Statistics from "@/views/Statistics.vue";
import WidgetGenerator from "@/views/WidgetGenerator.vue";

const routes = [
    {
        path: '/',
        component: DashboardLayout,
        redirect: '/admin/overview'
    },
    {
        path: '/home',
        component: Login,
        name: 'home',
        meta: {
          public: true
        }
    },
    {
        path: '/about',
        component: About,
        name: 'about',
        meta: {
          public: true
        }
    },
    {
        path: '/admin',
        component: DashboardLayout,
        redirect: '/admin/stats',
        meta: {
          public: false
        },
        children: [
            {
                path: 'overview',
                name: 'Overview',
                component: Overview
            },
            {
                path: 'bookings-list',
                name: 'Bookings',
                component: BookingsList
            },
            {
                path: 'trips-list',
                name: 'Trips',
                component: TripsList
            },
            {
                path: 'user_registration',
                name: 'Registration',
                component: UserRegistration
            },
            {
                path: 'affiliates-list',
                name: 'Affiliates',
                component: AffiliatesList,
            },
            {
                path: 'affiliates-list/edit-user/:code',
                name: 'Affiliate',
                props: true,
                component: UserRegistration,
            },
            {
                path: 'statistics',
                name: 'Statistics',
                component: Statistics
            },
            {
                path: 'widget',
                name: 'Widget',
                component: WidgetGenerator
            }
        ]
    },
    {path: '/:pathMatch(.*)*', component: ErrorPage}
]



/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
 function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes
