import axios from 'axios'
import URLSearchParams from 'url-search-params'
import Dater from '@/repositories/dater'
// import { mockUserData, mockLoginCheck } from '../repositories/mockData';

export const promiseWithDelay = (mockname, data, timeout = 300) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(data), timeout);
  })
}

function Api(root, seasy) {
  this.config = {
    root: root,
    seasy: seasy
  }
}

axios.interceptors.request.use(
  config => {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('fh_user_token')}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

/**
 * Creates API headers
 */
Api.prototype.createHeaders = function() {
  return {}
}

/**
 * Checks if the currently stored user session is still active, in order to
 * automatically login the user
 */
Api.prototype.checkSessionLogin = function() {
  // return promiseWithDelay('checkSessionLogin', { data: mockLoginCheck })
  return axios.get(this.config.root + '/api', this.createHeaders(), {});
}

Api.prototype.loginCheck = function(username, password) {
  // create request body, with user credentials
  let body = new URLSearchParams()
  body.append('username', username)
  body.append('password', password)
  // return promiseWithDelay('loginCheck', { data: mockLoginCheck })
  return axios.post(this.config.root + '/api/login_check', body, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  }).catch(error => {
    if ((error.response) && (!!error.response.data)) {
      throw new Error(error.response.data.message || 'An unknown error has occured');
    } else {
      throw new Error('An unknown error has occured. Please check your connection and try again.');
    }
})
}



Api.prototype.logout = function() {
  return new Promise((resolve, reject) => {
    axios
      .post(
        this.config.root + '/logout',
        {},
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
      .then(() => resolve(true), () => reject(false))
  })
}

// fetch user data for a given time period
Api.prototype.getUserData = function(impersonateUser) {
  let dates = Dater.getFormattedArray()
  const statsUrl = `${this.config.root}/api/stats/${dates.from}/${dates.to}`;
  let url = !!impersonateUser ? `${statsUrl}?impersonate=${impersonateUser.id}` : statsUrl;
  // return promiseWithDelay('getUserData', { data: mockUserData })
  return axios.get(url, this.createHeaders(), Dater.getFormattedArray())
}

Api.prototype.getTopSellingData = function(impersonateUser) {
  let dates = Dater.getFormattedArray()
  let statsUrl = `${this.config.root}/api/top-stats/${dates.from}/${dates.to}`
  let url = !!impersonateUser ? `${statsUrl}?impersonate=${impersonateUser.id}` : statsUrl;
  // return promiseWithDelay('getTopSellingData', { data: mockTopStats });
  return axios.get(url, this.createHeaders(), Dater.getFormattedArray())
}

// generate a report for the active time period
Api.prototype.userReport = function(report) {
  let dates = Dater.getFormattedArray()
  let url = `${this.config.root}/api/report/${dates.from}/${dates.to}/${report}`
  return axios.get(url, this.createHeaders(), Dater.getFormattedArray())
}

// generate an administrative report for the active time period
Api.prototype.affiliatesList = function() {
  let dates = Dater.getFormattedArray()
  let url = `${this.config.root}/api/admin/affiliates-list/${dates.from}`
  return axios.get(url, this.createHeaders())
}

//------------------------------------------------------------------------------
// get open crs data from Seasy
Api.prototype.openData = function() {
  const seasyAxios = axios.create({});
  return seasyAxios.get(`${this.config.seasy}/api/v2/crs/fetch?companies=true&ports=true`);
}

//------------------------------------------------------------------------------
// perform registration of user

Api.prototype.register = function(body) {
  // the affiliation details of the model already has a proper structure
  // and hence we can post it to the server without modifications
  return axios
    .post(
      `${this.config.root}/api/admin/register-user`,
      body,
      this.createHeaders()
    )
    .then(response => {
      return response.data['status']
    })
    .catch(error => {
      let exceptionMessage = error.response.data.message['error']
      throw new Error(exceptionMessage)
    })
}

//------------------------------------------------------------------------------
// fetch all input values to enable editing
/**
 * Get user details by affiliate code
 * @param code
 * @returns {AxiosPromise<any>}
 */
Api.prototype.getAffiliateByCode = function(code) {
  let url = `${this.config.root}/api/admin/user-details/${code}`
  return axios.get(url, this.createHeaders())
}


Api.prototype.editAffiliateScheme = function(body) {
  // the affiliation scheme details of the model already has a proper structure
  // and hence we can post it to the server without modifications
  return axios
    .post(
      `${this.config.root}/api/admin/edit-affiliate`,
      body,
      this.createHeaders()
    )
    .then(response => response)
    .catch(error => {
      let exceptionMessage = error.response.data.message['error']
      throw new Error(exceptionMessage)
    })
}


Api.prototype.deactivateAffiliate = function(body) {
  return axios
    .post(
      `${this.config.root}/api/admin/deactivate-affiliate`,
      body,
      this.createHeaders()
    )
    .then(response => response)
    .catch(error => {
      let exceptionMessage = error.response.data.message['error']
      throw new Error(exceptionMessage)
    })
}

// set invoice as paid
Api.prototype.setPaid = function(invoiceId) {
  let url = `${this.config.root}/api/admin/invoices/mark-invoice-paid/${invoiceId}`
  return axios.get(url, this.createHeaders())
}


// create invoices
Api.prototype.createInvoices = function() {
  let dates = Dater.getFormattedArray()
  let url = `${this.config.root}/api/admin/invoices/create/${dates.from}`
  return axios.get(url, this.createHeaders())
}

Api.prototype.createInvoice = function(affiliateId) {
  let dates = Dater.getFormattedArray()
  let url = `${this.config.root}/api/admin/invoices/create-single/${affiliateId}/${dates.from}`
  return axios.get(url, this.createHeaders())
}


// fetch bookings list with pagination
Api.prototype.fetchAdminStats = function(page) {
  let { from, to } = Dater.getFormattedArray()
  let url = `${this.config.root}/api/admin-stats/${from}/${to}`
  return axios.get(url, this.createHeaders(), Dater.getFormattedArray())
}

// fetch bookings list with pagination
Api.prototype.fetchBookingsTablePage = function(page, impersonateUser) {
  let dates = Dater.getFormattedArray()
  let statsUrl = `${this.config.root}/api/list-bookings/${dates.from}/${dates.to}/${page}`;
  let url = !!impersonateUser ? `${statsUrl}?impersonate=${impersonateUser.id}` : statsUrl;

  // return promiseWithDelay('fetchBookingsTablePage', { data: mockBookingsList });
  return axios.get(url, this.createHeaders(), Dater.getFormattedArray())
}

// fetch trips list with pagination
Api.prototype.fetchTripsTablePage = function(page, impersonateUser) {
  let dates = Dater.getFormattedArray()
  let statsUrl = `${this.config.root}/api/list-trips/${dates.from}/${dates.to}/${page}`;
  let url = !!impersonateUser ? `${statsUrl}?impersonate=${impersonateUser.id}` : statsUrl;

  // return promiseWithDelay('fetchTripsTablePage', { data: mockTripsList })
  return axios.get(url, this.createHeaders(), Dater.getFormattedArray())
}

let seasyUrl = 'https://www.ferryhopper.com';

let affiliatesBackendUrl =
  (window.ferryhopper && window.ferryhopper.api_url) || 'http://affiliates.test'

// initialize module
export default new Api(affiliatesBackendUrl, seasyUrl)
