<template>
  <div class="row">
    <div class="col-xs-12" v-if="affiliate.isDeactivated">
      <div class="alert alert-danger"><h4 style="margin-bottom: 0;">Affiliate is deactivated</h4></div>
    </div>

    <div class="col-xs-12 col-md-4 col-sm-6 ">
      <SimpleCard :title="'Affiliate Details'" :icon="'fa-user'" :loading="isWaiting">
        <template v-slot:content>

          <!-- USERNAME -->
          <div class="form-group has-feedback">
            <label class="input-labels" for="inputUser">Username</label>
              <input
                name="name"
                type="text"
                id="inputUser"
                placeholder="Username"
                required
                v-model="affiliate.name"
                class="form-control"
              />
          </div>

          <div class="form-group has-feedback">
            <label class="input-labels" for="inputUser">Password</label>
              <input
                name="password"
                type="text"
                id="inputPassword"
                placeholder="Password"
                required
                v-model="affiliate.password"
                class="form-control"
              />
          </div>

          <!-- EMAIL -->
          <div class="form-group has-feedback">
            <label class="input-labels" for="inputEmail">Email</label>
              <input
                name="contactEmail"
                type="email"
                class="form-control"
                id="inputEmail"
                placeholder="Email"
                required
                v-model="affiliate.contactEmail"
              />
          </div>

          <!-- MASTER CODES -->
          <div class="form-group has-feedback">
            <label class="input-labels" for="inputMaster">Master Code</label>
              <input
                name="masterCode"
                type="text"
                v-model="affiliate.masterCode"
                class="form-control"
                id="inputMaster"
                placeholder="Master Code"
                required
              />
          </div>

          <div>
            <AffiliationBubble
              v-for="code in affiliationCodes"
              :affiliate="affiliate"
              :code="code"
              :key="code"
            ></AffiliationBubble>
          </div>

          <!-- COLOR -->
          <div class="form-group ">
            <label class="input-labels" for="inputMaster">Color Code</label>
              <input
                class="form-control"
                name="color"
                type="color"
                v-model="affiliate.color"
                id="inputColor"
                required
              />
          </div>

          <!-- DATA URL -->
          <div class="form-group has-feedback">
            <label class="input-labels" for="inputUrl">Data Studio Link </label>
            <input name="dataUrl" type="text" v-model="affiliate.dataUrl" class="form-control " id="inputUrl">
          </div>

        </template>
      </SimpleCard>
    </div>

    <div class="col-xs-12 col-md-4 col-sm-6 ">
      <SimpleCard :title="'Model Details'" :icon="'fa-lock'" :loading="isWaiting">
        <template v-slot:content>
          <!-- AFFILIATION MODEL -->
          <div class="form-group has-feedback">
            <label class="input-labels" for="selectModel">Affiliation Model</label>
            <select
              v-model="affiliate.affiliationModel"
              class="form-control"
              id="selectModel"
            >
              <option
                v-for="model in affiliationModels"
                :value="model.value"
                :key="model.value"
              >{{ model.title }}</option>
            </select>
          </div>

          <!-- FIXED COMMISSION -->
          <div class="form-group has-feedback">
            <label class="input-labels" for="inputFixed">Commission (without fee)</label>
              <input
                name="fixedCommission"
                type="text"
                v-model.number="affiliate.fixedCommission"
                class="form-control"
                id="inputFixed"
                required
              />
          </div>

          <!-- FEE COMMISSION -->
          <div class="form-group has-feedback">
            <label class="input-labels" for="inputFee">Commission (with fee)</label>
              <input
                name="feeCommission"
                type="text"
                v-model.number="affiliate.feeCommission"
                class="form-control"
                id="inputfee"
                required
              />
          </div>

          <hr />

          <div class="form-group has-feedback">
            <label class="input-labels" for="inputService">Booking Fee</label>
              <input
                name="serviceFee"
                type="text"
                v-model.number="affiliate.serviceFee"
                class="form-control"
                id="inputService"
              />
          </div>

          <div class="form-group has-feedback">
            <label class="input-labels" for="selectServicefeeMode">Booking Fee Mode</label>
            <select
              v-model="affiliate.serviceFeeMode"
              class="form-control"
              id="selectServicefeeMode"
            >
              <option
                v-for="serviceFeeMode in serviceFeeModes"
                :value="serviceFeeMode"
                :key="serviceFeeMode"
              >{{ serviceFeeMode }}</option>
            </select>
          </div>

          <div class="form-group has-feedback" v-if="isEditing">
              <label
                class="input-labels form-group"
                for="selectSchemeUpdateTime"
              >Select when you want the scheme to be updated</label>
              <select
                id="selectSchemeUpdateTime"
                class="form-control form-group"
                v-model="isChangeAppliedFromCurrentMonth"
              >
                <option disabled value>Please select one</option>
                <option value="true">Update starting current month</option>
                <option value="false">Update starting next month</option>
              </select>
            </div>

          <div class="clearfix">
            <button class="btn btn-danger pull-right btn-fill" @click="onRegistrationSubmit">
              <span v-if="isWaiting">
                <i class="fa fa-spinner fa-spin"></i>
              </span>
              <span v-else>Save</span>
            </button>
          </div>
        </template>
      </SimpleCard>

      <StatsCard :loading="isLoading" v-if="!affiliate.isDeactivated">
        <template v-slot:header>
          <div class="icon-big">
            <i class="fa fa-exclamation-triangle"></i>
          </div>
        </template>
        <template v-slot:content>
          <div class="numbers">
            <p class="text-danger">Danger Zone</p>
          </div>
        </template>
        <template v-slot:extra v-if="!isWaiting">
            <button class="btn btn-danger btn-block mb20" @click="onDeactivationSubmit()">Deactivate user</button>
            <p class="text-danger">
              Deactivation means that the affiliate will no longer be able to
              access his personal dashboard. However, no data loss will occur. Deactivating a user cannot be undone.
            </p>
          </template>
      </StatsCard>
    </div>

    <div class="col-xs-12 col-sm-4" v-if="isEditing">
      <StatsCard :title="'Unpaid Invoices'" :loading="isLoading">
        <template v-slot:header>
          <div class="icon-big">
            <i class="fa fa-eur"></i>
          </div>
        </template>
        <template v-slot:content>
          <div class="numbers">
            <p>Pending Payments</p>
            {{ totalInvoiceAmount }}
          </div>
        </template>
        <template v-slot:extra v-if="!isWaiting">
          <table class="table">
            <tbody>
              <tr>
                <th>Month</th>
                <th>Year</th>
                <th>Amount</th>
              </tr>
              <tr v-for="(invoice,index) in invoicesList" :key="'invoice-' + index">
                <td>{{invoice.month}}</td>
                <td>{{invoice.year}}</td>
                <td>{{invoice.amount}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td colspan="2">{{ totalInvoiceAmount }}</td>
              </tr>
            </tfoot>
          </table>
        </template>
      </StatsCard>
    </div>

  </div>
</template>

<script>
import { schema } from '@/models/schema'
import SimpleCard from '@/components/UIComponents/Cards/SimpleCard'
import AffiliationBubble from '@/components/shared/AffiliationBubble'
import AffiliateModel from '@/models/AffiliateModel'
import ApiGateway from '../services/ApiGateway'
import StatsCard from '@/components/UIComponents/Cards/StatsCard.vue'
import { mapState } from 'vuex'


export default {
  name: 'UserRegistrationComponent',
  components: {
    SimpleCard,
    StatsCard,
    AffiliationBubble
  },
  props: {
    code: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    affiliate: new AffiliateModel(),
    isWaiting: false,
    isEditing: false,
    invoicesList: [],
    totalInvoiceAmount: 0,
    isChangeAppliedFromCurrentMonth: '',
    serviceFeeModes: [
      'ABSOLUTE',
      'RELATIVE'
    ],
    affiliationModels: window.ferryhopper && window.ferryhopper.affiliation_models
  }),
  created() {
    if (!this.code) {
      return;
    }
    this.isEditing = true;
    // On create populate inputs using affiliate details
    this.isWaiting = true;
    this.$store.dispatch('lockUi/lockUiAction')
      ApiGateway.getAffiliateByCode(this.code).then(response => {
        this.affiliate = new AffiliateModel(response.data.scheme);
        this.invoicesList = response.data.invoices;
        this.totalInvoiceAmount = response.data.invoicesAmount;
        this.$store.dispatch('lockUi/unlockUiAction');
        this.isWaiting = false;
      })
  },
  methods: {
    async onDeactivationSubmit() {

      try {
        this.$store.dispatch('lockUi/lockUiAction')
        this.isWaiting = true;
        await ApiGateway.deactivateAffiliate(this.affiliate);
        this.affiliate.isDeactivated = true;
      } catch(error) {
        this.errorNotify('An error has occurred');
      }

      this.$store.dispatch('lockUi/unlockUiAction');
      this.isWaiting = false;
    },
    async onRegistrationSubmit() {

      try {

        this.isWaiting = true;
        await schema.validate(this.affiliate);

        if (('' === this.isChangeAppliedFromCurrentMonth) && this.isEditing) {
          this.$store.dispatch('notificationsModule/warningNotify', 'Please select when you want the scheme to be updated');
          this.isWaiting = false;
          return;
        }

        this.$store.dispatch('lockUi/lockUiAction');
        const response = this.isEditing ?
          await ApiGateway.editAffiliateScheme({
            ...this.affiliate,
            startFromThisMonth: this.isChangeAppliedFromCurrentMonth
          }) :
          await ApiGateway.register(this.affiliate);
        this.onRegistrationResponse();
      } catch(error) {
        this.onRegistrationError(error)
      }
      this.isWaiting = false;
    },
    onRegistrationResponse() {
      this.onRegistrationSuccess();
      this.$store.dispatch('lockUi/unlockUiAction')
    },
    onRegistrationSuccess() {
      this.$store.dispatch('notificationsModule/successNotify', `Affiliate ${this.affiliate.name} has been ${this.isEditing ? 'edited' : 'created'}`)
      if (!this.isEditing) {
        this.affiliate = new AffiliateModel();
      }
    },
    onRegistrationError(error) {
      this.$store.dispatch('lockUi/unlockUiAction')
      this.$store.dispatch('notificationsModule/errorNotify', error.toString().replace('ValidationError: ', 'Input error: '));
    },
  },
  computed: {
    ...mapState({
      isLoading: state => state.lockUi.blockUi
    }),
    affiliationCodes() {
      return (this.affiliate.masterCode || '').replace(/ /g, ',').replace(/,,/g, ',').split(',');
    }
  }
}
</script>
