// get current environemnt, is one is set
// note: this structure is generated inside the .twig files through symfony
// on the served version of the app
window.ferryhopper = window.ferryhopper || {
  environment: 'dev',
}

import { createApp } from 'vue'
import store from '@/store/index'
import router from '@/routes/index'
import Ports from '@/repositories/ports'
import Companies from '@/repositories/companies'
import ApiGateway from '@/services/ApiGateway'

import App from '@/App.vue'

// Initializes vue application and mounts it to the #app dom element
const initializeVueApp = () => {
  const app = createApp(App);
  app.use(router).use(store);
  app.mount('#app');
}

ApiGateway.openData()
  .then(({ data }) => {
    Ports.initialize(data)
    Companies.initialize(data)
  })
  .catch(() => {})
  .finally(() => initializeVueApp())

import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.css'
import '@/assets/sass/base.scss'
