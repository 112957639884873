<template>
  <div :class="{'nav-open': showSidebar}">


    <router-view></router-view>

    <SideBar type="navbar">
      <ul class="nav navbar-nav">
        <li>
          <a>
            <i class="ti-settings"></i>
            <p>Logout</p>
          </a>
        </li>
        <li class="divider"></li>
      </ul>
    </SideBar>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import SideBar from '@/components/UIComponents/SidebarPlugin/SideBar.vue'


export default {
  name: 'App',
  components: {
    SideBar
  },
  async created() {
    try {
      await this.$store.dispatch('authentication/validateActiveSession')
    } catch (exception) {
      console.log('Started app without active session')
    }
  },
  computed: {
    ...mapState({
      showSidebar: state => state.lockUi.showSidebar,
      sidebarLinks: state => state.lockUi.sidebarLinks
    })
  }
}
</script>
